<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>

    <!-- Header -->
    <main-header/>
    <!--  -->
<!--    <section class="d-none d-lg-block px-md-3 bg-white" id="fixedFilter" style="z-index:20!important">
      <div class="container">
        <ul class="_flex _center subheading list-unstyled bold">
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn _flex text-dark">
              Prix
              <span class="ml-1 bg-black rounded-pill px-1 medium" style="font-size:12px;height:20px;line-height:20px;">0 $ | 1000 $</span>
              <i class="fal fa-angle-down ml-2"></i>
            </a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-price">
              <div class="row no-gutters">
                <div class="col-12">
                  <div class="px-4 subheading">
                    <div>O $ <span style="color:#ccc">|</span> 1000 $</div>
                    <div class="mt-2 mb-4"></div>
                  </div>
                  <div class="border-top px-3 price__reinit" style="height:24px;line-height:48px;">
                    <div class="_flex c-pointer">
                      <i class="icon-quit mx-2"></i> Réinitialiser le filtre
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn text-dark">Thème <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-theme">
              <div class="row no-gutters">
                <div class="col-6">
                  <a href="">Abstraite</a>
                  <a href="">Street art</a>
                  <a href="">Nus</a>
                  <a href="">Nature morte</a>
                  <a href="">Fantastique</a>
                  <a href="">Flore</a>
                  <a href="">Art aborigène</a>
                  <a href="">Nature</a>
                  <a href="">Mode</a>
                  <a href="">Politique et historique</a>
                  <a href="">Minimalisme</a>
                  <a href="">Monumentale</a>
                  <a href="">Gravure</a>
                  <a href="">Art Oriental</a>
                  <a href="">Art brut</a>
                  <a href="">Conceptuel</a>
                  <a href="">Cinéma</a>
                  <a href="">Musique</a>
                  <a href="">Nourriture</a>
                  <a href="">Vanités</a>
                </div>
                <div class="col-6">
                  <a href="">Architecture</a>
                  <a href="">Icônes</a>
                  <a href="">Portrait</a>
                  <a href="">Paysage urbain</a>
                  <a href="">Graphisme</a>
                  <a href="">Sous l'eau</a>
                  <a href="">Noir et blanc</a>
                  <a href="">Scène de vie</a>
                  <a href="">Animaux</a>
                  <a href="">Pop Art</a>
                  <a href="">Géométrique</a>
                  <a href="">Sculpture murale</a>
                  <a href="">Vu du ciel</a>
                  <a href="">Art africain</a>
                  <a href="">Jardin</a>
                  <a href="">Bande dessinée</a>
                  <a href="">Art tribal</a>
                  <a href="">Sport</a>
                  <a href="">Religion</a>
                  <a href="">Vehicules</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn text-dark">Couleur <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-couleur">
              <div class="row no-gutters">
                <div class="col-6">
                  <a href="">Argenté</a>
                  <a href="">Blanc</a>
                  <a href="">Bleu foncé</a>
                  <a href="">Doré</a>
                  <a href="">Gris</a>
                  <a href="">Marron</a>
                  <a href="">Orange</a>
                  <a href="">Rouge</a>
                  <a href="">Vert</a>
                </div>
                <div class="col-6">
                  <a href="">Beige</a>
                  <a href="">Bleu ciel</a>
                  <a href="">Bleu marine</a>
                  <a href="">Doré</a>
                  <a href="">Jaune</a>
                  <a href="">Noir</a>
                  <a href="">Rose</a>
                  <a href="">Transparent</a>
                  <a href="">Violet</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn text-dark">Format <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-format">
              <div class="row no-gutters">
                <div class="col-12">
                  <a href="">Petit (&lt; 20 x 20 inch)</a>
                  <a href="">Moyen (&lt; 45 x 45 inch)</a>
                  <a href="">Grand (> 45 x 45 inch)</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn text-dark">Orientation <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-orientation">
              <div class="row no-gutters">
                <div class="col-12">
                  <a href="">Portrait</a>
                  <a href="">Paysage</a>
                  <a href="">Carré</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn text-dark">Tirage <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-tirage">
              <div class="row no-gutters">
                <div class="col-12">
                  <a href="">Unique</a>
                  <a href="">Multiple</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>-->
    <!--  -->
    <div class="container">
      <!-- Breadcrumb -->
      <div class="_flex _center">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb  bg-transparent tiny">
            <li class="breadcrumb-item"><a href="#">Accueil</a></li>
            <li class="breadcrumb-item active" aria-current="page">Résultats</li>
          </ol>
        </nav>
      </div>
    </div>
    <!--  -->
<!--    <section class="d-none d-lg-block" style="background-color:#000;color:white;" id="fixedFilterBlack">
      <div class="container">
        <ul class="_flex _center _wrap subheading list-unstyled bold">
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn _flex">
              Prix
              <span class="ml-1 bg-white rounded-pill px-1 medium" style="font-size:12px;height:20px;line-height:20px;">0 $ | 1000 $</span>
              <i class="fal fa-angle-down ml-2"></i>
            </a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-price">
              <div class="row no-gutters text-black">
                <div class="col-12">
                  <div class="px-4 subheading">
                    <div>O $ <span style="color:#ccc">|</span> 1000 $</div>
                    <div class="mt-2 mb-4"></div>
                  </div>
                  <div class="border-top px-3 price__reinit" style="height:24px;line-height:48px;">
                    <div class="_flex c-pointer">
                      <i class="icon-quit mx-2"></i> Réinitialiser le filtre
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn">Thème <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-theme">
              <div class="row no-gutters">
                <div class="col-6">
                  <a href="">Abstraite</a>
                  <a href="">Street art</a>
                  <a href="">Nus</a>
                  <a href="">Nature morte</a>
                  <a href="">Fantastique</a>
                  <a href="">Flore</a>
                  <a href="">Art aborigène</a>
                  <a href="">Nature</a>
                  <a href="">Mode</a>
                  <a href="">Politique et historique</a>
                  <a href="">Minimalisme</a>
                  <a href="">Monumentale</a>
                  <a href="">Gravure</a>
                  <a href="">Art Oriental</a>
                  <a href="">Art brut</a>
                  <a href="">Conceptuel</a>
                  <a href="">Cinéma</a>
                  <a href="">Musique</a>
                  <a href="">Nourriture</a>
                  <a href="">Vanités</a>
                </div>
                <div class="col-6">
                  <a href="">Architecture</a>
                  <a href="">Icônes</a>
                  <a href="">Portrait</a>
                  <a href="">Paysage urbain</a>
                  <a href="">Graphisme</a>
                  <a href="">Sous l'eau</a>
                  <a href="">Noir et blanc</a>
                  <a href="">Scène de vie</a>
                  <a href="">Animaux</a>
                  <a href="">Pop Art</a>
                  <a href="">Géométrique</a>
                  <a href="">Sculpture murale</a>
                  <a href="">Vu du ciel</a>
                  <a href="">Art africain</a>
                  <a href="">Jardin</a>
                  <a href="">Bande dessinée</a>
                  <a href="">Art tribal</a>
                  <a href="">Sport</a>
                  <a href="">Religion</a>
                  <a href="">Vehicules</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn">Couleur <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-couleur">
              <div class="row no-gutters">
                <div class="col-6">
                  <a href="">Argenté</a>
                  <a href="">Blanc</a>
                  <a href="">Bleu foncé</a>
                  <a href="">Doré</a>
                  <a href="">Gris</a>
                  <a href="">Marron</a>
                  <a href="">Orange</a>
                  <a href="">Rouge</a>
                  <a href="">Vert</a>
                </div>
                <div class="col-6">
                  <a href="">Beige</a>
                  <a href="">Bleu ciel</a>
                  <a href="">Bleu marine</a>
                  <a href="">Doré</a>
                  <a href="">Jaune</a>
                  <a href="">Noir</a>
                  <a href="">Rose</a>
                  <a href="">Transparent</a>
                  <a href="">Violet</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn">Format <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-format">
              <div class="row no-gutters">
                <div class="col-12">
                  <a href="">Petit (&lt; 20 x 20 inch)</a>
                  <a href="">Moyen (&lt; 45 x 45 inch)</a>
                  <a href="">Grand (> 45 x 45 inch)</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn">Orientation <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-orientation">
              <div class="row no-gutters">
                <div class="col-12">
                  <a href="">Portrait</a>
                  <a href="">Paysage</a>
                  <a href="">Carré</a>
                </div>
              </div>
            </div>
          </li>
          <li class="fixedFilterElt mr-5 mr-10-lg mr-12-xl _flex dropdown">
            <a class="c-pointer dropbtn">Tirage <i class="fal fa-angle-down ml-2"></i></a>
            <div class="dropdown-content dropdown-content-filters dropdown-content-filters-tirage">
              <div class="row no-gutters">
                <div class="col-12">
                  <a href="">Unique</a>
                  <a href="">Multiple</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>-->
    <div class="d-none d-lg-block p-2 border-bottom">
      <div class="container">
        <ul class="_flex _center body-1 text-muted list-unstyled">
          <li class="_flex mr-5 mr-10-lg mr-12-xl">
            Les plus populaires<i class="fal fa-angle-down ml-2"></i>
          </li>
          <li class="_flex ">
            {{count}} résultat{{count > 1 ?'s': ''}}
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
<!--    <section class="container d-block d-lg-none">
      <div class="_flex _between subheading">
        <div class="bg-black mr-2 w-100 p-3 text-center">Trier</div>
        <div class="bg-black ml-2 w-100 p-3 text-center">Filtrer</div>
      </div>
    </section>-->
    <!--  -->
    <div class="container px-15-lg px-20-xl">
      <div class=" my-10">
        <!-- <div class="row d-flex flex-row flex-wrap _center my-10"> -->
        <div class="my-10">
          <div class="card-columns" v-if="artworks.length">
            <!-- -->
            <artwork-card v-for="artwork in artworks" :artwork="artwork"
              :key="artwork.id" />
          </div>
          <template v-if="!loading && !artworks.length">
            <no-results />
          </template>
        </div>
      </div>
    </div>
    <!--  -->
    <main-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: true
  }),
  computed: {
    artworks() {
      return this.$store.getters.artworks.data || [];
    },
    count() {
      return this.$store.getters.artworks.count || 0;
    },
  },
  mounted() {
    // When the user scrolls the page, execute myFunction

    window.onscroll = function() {
      scrollFunction();
    };

    // Get the filter
    var fixedFilter = document.getElementById("fixedFilter");

    //Get the button:
    var mybutton = document.getElementById("myBtn");

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position

    // When the user scrolls down 20px from the top of the document, show the button

    function scrollFunction() {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        // fixedFilter.classList.add("sticky");
        fixedFilter.style.top = "0";
        mybutton.style.display = "block";
      } else {
        fixedFilter.style.top = "-60px";
        // fixedFilter.classList.remove("sticky");
        // fixedFilter.style.display = "none";
        mybutton.style.display = "none";
      }
    }

    this.$store.dispatch("searchArtworks", this.$route.query.q).then(() => {
      this.loading = false;
    }).catch(e => {
      this.loading = false;
    });
  },
};
</script>

<style></style>
